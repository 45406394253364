import React from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import './richTextBlock.scss'

const RichTextBlock = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="rte__heading">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="rte__heading">{children}</h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h4 className="rte__heading">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5 className="rte__heading">{children}</h5>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <h6 className="rte__heading">{children}</h6>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="rte__paragraph">{children}</p>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li className="rte__list-item">{children}</li>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="rte__list">{children}</ul>
      ),
    },
  }

  if (!data) return false

  return <div className="rte">{renderRichText(data, options)}</div>
}

export default RichTextBlock
