import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner'
import RichTextBlock from '../components/richTextBlock/RichTextBlock'

class PrivacyPolicyPage extends React.Component {
  render() {
    const privacyPolicyHeader = get(this, 'props.data.privacyPolicyHeader')
    const privacyPolicyBody = get(this, 'props.data.privacyPolicyBody')

    return (
      <Layout location={this.props.location}>
        <Seo title="Privacy Policy" />
        <div className="container-fluid container-fluid--page">
          <TextBanner
            textOne={privacyPolicyHeader.textOne}
            textTwo={privacyPolicyHeader.textTwo}
          />
          <RichTextBlock data={privacyPolicyBody.body} />
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicy {
    privacyPolicyHeader: contentfulTextBanner(
      textBannerName: { eq: "PrivacyPolicyHeader" }
    ) {
      textBannerName
      textOne
      textTwo
    }
    privacyPolicyBody: contentfulBasicPage(
      basicPage: { eq: "Privacy Policy" }
    ) {
      body {
        raw
      }
    }
  }
`
